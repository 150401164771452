import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
const SalesBarChart = ({ monthlyBalance, monthlyPaidAmount }) => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );

  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Paid Amount",
        backgroundColor: documentStyle.getPropertyValue("--pink-500"),
        borderColor: documentStyle.getPropertyValue("--pink-500"),
        data: monthlyPaidAmount,
      },
      {
        label: "Balance",
        backgroundColor: documentStyle.getPropertyValue("--blue-500"),
        borderColor: documentStyle.getPropertyValue("--blue-500"),
        data: monthlyBalance,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          fontColor: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
          drawBorder: false,
        },
      },
    },
  };

  return (
    <div style={{ minWidth: "40vw", overflowX: "auto" }}>
      <Chart type="bar" data={data} options={options} />
    </div>
  );
};

export default SalesBarChart;
