import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import Footer from "../../helper/Footer";
import { bankApi } from "../../assets/apis"; // Assuming you have an API file for banks
import { useNavigate } from "react-router-dom";
import ConfirmPortal from "../../uiModals/confirmModel/ConfirmPortal";
import { hasPermission } from "../../utils/helpers";
import { useSelector } from "react-redux";
export const fetchBanks = async () => {
  const response = await axios.get(bankApi);
  return response.data;
};

const Bank = () => {
  const { loggedUser } = useSelector((state) => state.Auth);
  const [openPortal, setOpenPortal] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [bankData, setBankData] = useState([]);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["banks"],
    queryFn: fetchBanks,
  });

  useEffect(() => {
    if (data?.banks) {
      setBankData(data.banks);
    }
  }, [data]);

  const deleteBank = async (id) => {
    handleClosePortal();

    try {
      const response = await axios.delete(`${bankApi}/${id}`);

      if (response.status === 200) {
        toast.success("Bank deleted successfully");
        queryClient.invalidateQueries("bank");
      } else {
        toast.error("Failed to delete bank. Please try again.");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "Failed to delete bank. Please try again"
      );
    }
  };

  // Function to close the modal
  const handleOpenPortal = (data) => {
    setDeletingItem(data);
    setOpenPortal(true);
  };
  const handleClosePortal = () => {
    setDeletingItem(null);
    setOpenPortal(false);
  };

  const handleInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setSearchTerm(value);
    }
  };

  const filteredData = bankData?.filter((item) =>
    Object.values(item).some((val) =>
      val?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className="main-panel">
      <div className="content-wrapper  ">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title"> Bank Report</div>
            {hasPermission(loggedUser, 47) && (
              <button
                className="btn btn-primary px-2 py-1"
                onClick={() => navigate("/add/bank")}
              >
                Add Bank
              </button>
            )}
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name"></label>
                <input
                  type="text"
                  className="form-control "
                  name="name"
                  id="name"
                  placeholder="search"
                  value={searchTerm}
                  onChange={(e) => {
                    handleInputChangeWithCondition(e, /^[a-zA-Z\s\b]+$/);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column  align-items-center">
            <DataTable
              value={filteredData}
              style={{ width: "100%" }}
              className="mytable"
            >
              <Column
                className="border-bottom border-top"
                header="SR. No."
                body={(rowData, { rowIndex }) => rowIndex + 1}
                style={{minWidth:"4rem"}}
              />

              <Column
                className="border-bottom border-top"
                field="name"
                header="NAME "
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "12rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="branch"
                header="BRANCH"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "8rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="account_no"
                header="ACCOUNT"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "8rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="ifsc"
                header="IFSC"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "8rem",
                  padding: "0 5px",
                }}
              />

              <Column
                className="border-bottom border-top"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      maxWidth: "2rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {hasPermission(loggedUser, 48) && (
                      <i
                        onClick={(e) => navigate(`/edit/bank/${data.id}`)}
                        className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                      />
                    )}
                    {hasPermission(loggedUser, 49) && (
                      <i
                        onClick={(e) => {
                          handleOpenPortal(data);
                        }}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
          </div>
        </div>
      </div>
      {openPortal && (
        <ConfirmPortal
          onClose={handleClosePortal}
          type={"Bank"}
          name={deletingItem.name}
          id={deletingItem.id}
          handleDalete={deleteBank}
        ></ConfirmPortal>
      )}
    </div>
  );
};

export default Bank;
