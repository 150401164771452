import React, { useEffect } from "react";
import Footer from "./../../helper/Footer";
import Overview from "./Overview";
import SalesBarChart from "./SalesBarChart";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { dashboardApi } from "../../assets/apis";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../spinner/first_spinner/Spinner";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../redux-store/sidebarActiveList";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import CoverSpin from "../../spinner/second_spinner/CoverSpin";
import { expiryDetails } from "../../utils/helpers";
import SecondSpinner from "../../spinner/third_spinner/SecondSpinner";
import TopClient from "./TopClient";
function Dashboard() {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { companyData } = useSelector((state) => state.Company);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const fetchDashboardReports = async () => {
    const response = await axios.get(`${dashboardApi}/report`);
    return response.data;
  };

  const { isLoading, isError, data, error, isFetching } = useQuery({
    queryKey: ["dashboard"],
    queryFn: fetchDashboardReports,
    retry: false,
  });

  useEffect(() => {
    queryClient.invalidateQueries(["dashboard"]);
  }, [FYear.value, queryClient]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: null }));
    dispatch(setSiderBarActiveLink({ link: "dashboard" }));
  }, []);

  useEffect(() => {}, []);
  if (isLoading || isFetching)
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <SecondSpinner />
        </div>
      </div>
    );

  if (isError) {
    return (
      <div>
        {" "}
        {error?.response?.data?.message ||
          "There is no analytics belong to the corrent company or financial year"}
      </div>
    );
  }
  return (
    <div className="main-panel">
      {expiryDetails(companyData?.expired)?.isShow && (
        <div className="content-wrapper py-1 pt-3">
          <div className="card col-lg-12 p-3 py-2">
            <span style={{ color: "#ffaf00" }}>
              <i className="mdi mdi-alert-box mdi-24px"></i>
              {expiryDetails(companyData?.expired)?.isExpired ? (
                <>
                  &nbsp; Your free trial has been expired.&nbsp;To enjoy
                  uninterrupted services, subscribe right away. Pick a plan that
                  works for you.&nbsp;
                  <Link to="/pick/plan" style={{ textDecoration: "none" }}>
                    Click Here
                  </Link>
                </>
              ) : (
                <>
                  &nbsp;Your free trial expire&nbsp;
                  {expiryDetails(companyData?.expired)?.remainingDays > 1
                    ? expiryDetails(companyData?.expired)?.remainingDays === 2
                      ? "Tomorrow"
                      : expiryDetails(companyData?.expired)?.remainingDays === 1
                      ? "Today"
                      : `within ${
                          expiryDetails(companyData?.expired)?.remainingDays
                        } days`
                    : "Today"}
                  . To enjoy uninterrupted services, subscribe right away. Pick
                  a plan that works for you.&nbsp;
                  <Link to="/pick/plan" style={{ textDecoration: "none" }}>
                    Click Here
                  </Link>
                </>
              )}
            </span>
          </div>
        </div>
      )}

      <div className="content-wrapper py-1">
        <Overview
          data={{
            sales: data?.data?.sales,
            purchase: data?.data?.purchase,
            contacts: data?.data?.contacts,
          }}
        />
      </div>
      <div className="content-wrapper py-1">
        <div className="card col-lg-12 p-3">
          <div className="card-title text-uppercase col-lg-12 p-3">Sales</div>
          <SalesBarChart
            monthlyPaidAmount={data?.data?.monthlyPaidAmount}
            monthlyBalance={data?.data?.monthlyBalance}
          />
        </div>
      </div>
      <div className="content-wrapper py-1">
        {/* <div className="row"> */}
        <div className="card col-lg-6 p-3  mt-2">
          <div className="card-title text-uppercase col-lg-6 p-3 m-0">
            Top Clients
          </div>
          <TopClient mostVisitedClients={data?.data?.mostVisitedClients} />
        </div>
        {/* <div className="card col-lg-6 p-3">asfd</div> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
